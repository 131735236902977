.login-container1 {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .logo-container1 {
    margin-bottom: 20px;
  }
  
  .logo1 {
    width: 100px;
  }
  
  .form-group1 {
    margin-bottom: 20px;
  }
  
  .label1 {
    display: block;
    margin-bottom: 5px;
  }
  
  .input1[type='text'],
  .input1[type='password'] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .button1 {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .error-message1 {
    margin-top: 10px;
    color: red;
  }
  